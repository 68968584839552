import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import './LoginPage.css';
import { Link } from "react-router-dom";

function LoginPage({ setLoggedIn }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState(''); // For success messages

  const auth = getAuth();

  const loginUser = (e) => {
    e.preventDefault(); // Prevents the form from refreshing the page
    if (!email || !password) {
      setError("Email or password is empty.");
      return;
    }

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Logged in
        setLoggedIn(true);
        console.log("Login successful.");
      })
      .catch((error) => {
        setError("Incorrect email or password.");
        console.error("Login error:", error.message);
      });
  };

  const resetPassword = () => {
    if (!email) {
      setError("Please enter your email to reset your password.");
      return;
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setMessage("Password reset email sent. Check your inbox.");
        setError(''); // Clear any previous error messages
      })
      .catch((error) => {
        setError("Error sending password reset email. Please try again.");
        console.error("Password reset error:", error.message);
      });
  };

  return (
    <div className="login-container">
      <form onSubmit={loginUser} className="login-form">
        <h2>Log In</h2>
        {error && <p className="error">{error}</p>}
        {message && <p className="success">{message}</p>}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Log In</button>
        <p
          className="forgot-password-link"
          onClick={resetPassword}
          style={{ cursor: "pointer", color: "blue", marginTop: "10px" }}
        >
          Forgot Password?
        </p>
        <Link to="/signup">Don't have an account yet? Sign Up</Link>
        <br />
        <Link to="/saferidesignup" style={{ marginTop: '10px' }}>
          Volunteer to be a SafeRide driver
        </Link>
      </form>
    </div>
  );
}

export default LoginPage;
