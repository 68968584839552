import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, update, set, push } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './Request.css';

function BookingModal({ isOpen, onClose, onBook, availableSeats, contactNumber }) {
  const [seatsToBook, setSeatsToBook] = useState('');

  const handleBook = () => {
    const seats = parseInt(seatsToBook, 10);
    if (seats > 0 && seats <= availableSeats) {
      onBook(seats);
      alert(`Ride Fulfilled! Contact number: ${contactNumber}`);
      onClose();
    } else {
      alert('Please enter a valid number of seats.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Fulfill Request</h2>
        <p>Available Seats: {availableSeats}</p>
        <input
          type="number"
          value={seatsToBook}
          onChange={(e) => setSeatsToBook(e.target.value)}
          placeholder="Number of seats"
        />
        <button onClick={handleBook}>Fulfill</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
}

function Request() {
  const [rides, setRides] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showUnfulfilledOnly, setShowUnfulfilledOnly] = useState(false);
  const [bookingModalOpen, setBookingModalOpen] = useState(false);
  const [currentRideForBooking, setCurrentRideForBooking] = useState(null);
  const [contactNumber, setContactNumber] = useState(null);
  const navigate = useNavigate();
  const db = getDatabase();

  useEffect(() => {
    fetchRides();
  }, [showUnfulfilledOnly]);

  const fetchRides = () => {
    const ridesRef = ref(db, 'rideRequest');
    const usersRef = ref(db, 'users');
    const now = new Date();
    now.setHours(now.getHours() - 24);

    onValue(ridesRef, (snapshot) => {
      const ridesData = [];
      const userMap = {};

      onValue(usersRef, (usersSnapshot) => {
        usersSnapshot.forEach((userSnapshot) => {
          const user = userSnapshot.val();
          userMap[userSnapshot.key] = {
            username: user.username,
            contact: user.number, // Fetching the correct "number" field
          };
        });

        snapshot.forEach((childSnapshot) => {
          const ride = childSnapshot.val();
          const rideDate = new Date(ride.date);

          if (rideDate < now || (showUnfulfilledOnly && ride.seats <= 0)) {
            return;
          }

          if (userMap[ride.userID]) {
            ride.username = userMap[ride.userID].username;
            ride.contact = userMap[ride.userID].contact; // Adding contact number
          } else {
            ride.username = 'Unknown';
            ride.contact = 'N/A';
          }

          ridesData.push({ id: childSnapshot.key, ...ride });
        });

        ridesData.sort((a, b) => new Date(a.date) - new Date(b.date));
        setRides(ridesData);
        setIsLoading(false);
      });
    });
  };

  const openBookingModal = (ride) => {
    setCurrentRideForBooking(ride);
    setContactNumber(ride.contact); // Set the contact number for the modal
    setBookingModalOpen(true);
  };

  const bookSeats = (numberOfSeats) => {
    if (!currentRideForBooking || numberOfSeats <= 0) return;
  
    const updatedSeats = currentRideForBooking.seats - numberOfSeats;
    const rideRef = ref(db, `rideRequest/${currentRideForBooking.id}`);
    update(rideRef, { seats: updatedSeats })
      .then(() => {
        recordBooking(currentRideForBooking.id, currentRideForBooking); // Pass only the ride details
        fetchRides();
      })
      .catch((error) => {
        console.error('Error updating seats:', error);
        alert('Failed to fulfill request.');
      });
  
    setBookingModalOpen(false);
  };
  
  const recordBooking = (rideId, rideInfo) => {
    const bookingRef = ref(db, 'bookings');
    const newBookingRef = push(bookingRef);
    const bookerUserID = getAuth().currentUser?.uid;
  
    set(newBookingRef, {
      rideID: rideId,
      bookerUserID: bookerUserID,
      providerUserID: rideInfo.userID,
      type: 'request', // Set the type as 'request'
    })
      .then(() => {
        console.log('Request fulfillment recorded successfully.');
      })
      .catch((error) => {
        console.error('Error recording:', error);
      });
  };
  

  if (isLoading) {
    return <div className="loading-container">Loading requests...</div>;
  }

  return (
    <div className="rides-container">
      <div className="filter-container">
        <label>
          <input
            type="checkbox"
            checked={showUnfulfilledOnly}
            onChange={(e) => setShowUnfulfilledOnly(e.target.checked)}
          />
          Unfulfilled Requests Only
        </label>
      </div>

      <button onClick={() => navigate('/AddRequest')} className="add-ride-button">
        Add Request
      </button>

      {rides.map((ride) => (
        <div key={ride.id} className="ride-modern">
          <div className="ride-modern-header">
            <div className="ride-modern-locations">
              <span className="ride-from">{ride.fromLocation}</span>
              <span className="ride-arrow">→</span>
              <span className="ride-to">{ride.toLocation}</span>
            </div>
            <div className="ride-modern-seats">
              {ride.seats > 0 ? (
                <span className="seats-available">
                  {ride.seats} {ride.seats === 1 ? 'seat(s) requested' : 'seat(s) requested'}
                </span>
              ) : (
                <span className="seats-unavailable">Request Fulfilled</span>
              )}
            </div>
          </div>
          <div className="ride-modern-details">
            <p className="ride-date-time">
              {new Date(ride.date).toLocaleDateString()} • {ride.time}
            </p>
            <p className="ride-donation">
              <strong>Donation:</strong> ${ride.donationRequested || '0'}
            </p>
            {ride.additionalInfo && (
              <p className="ride-additional-info">
                <strong>Info:</strong> {ride.additionalInfo}
              </p>
            )}
            <p className="ride-user">
              <strong>Posted By:</strong> {ride.username}
            </p>
          </div>
          {ride.seats > 0 && (
            <button
              onClick={() => openBookingModal(ride)}
              className="ride-modern-book-btn"
            >
              Fulfill
            </button>
          )}
        </div>
      ))}

      {bookingModalOpen && currentRideForBooking && (
        <BookingModal
          isOpen={bookingModalOpen}
          onClose={() => setBookingModalOpen(false)}
          onBook={bookSeats}
          availableSeats={currentRideForBooking.seats}
          contactNumber={contactNumber}
        />
      )}
    </div>
  );
}

export default Request;
